<template>
  <div class="c-body-widget-guest-information" :style="widgetStyle">
    <img
      v-if="showImage"
      class="c-body-widget-guest-information__image"
      :src="getImageUrl(image, '_appthumb', '_appthumb')"
      :class="[showSquareBorders ? 'show-square-borders' : '']"
    />
    <div class="c-body-widget-guest-information__title" :style="{ color: textColor }">
      {{ text }}
    </div>
    <div class="c-body-widget-guest-information__dates">
      <div class="c-body-widget-guest-information__dates__block">
        <div class="c-body-widget-guest-information__dates__block__title" :style="{ color: textColor }">
          {{ $getString("CHECK_IN") }}
        </div>
        <div class="c-body-widget-guest-information__dates__block__date" :style="{ color: textColor }">
          {{ checkInDate }}
        </div>
      </div>
      <stay-ui-icon icon="arrow-right-02" :color="textColor" :size="20" variant="stroke" type="sharp" />
      <div class="c-body-widget-guest-information__dates__block" style="text-align: right">
        <div class="c-body-widget-guest-information__dates__block__title" :style="{ color: textColor }">
          {{ $getString("CHECK_OUT") }}
        </div>
        <div class="c-body-widget-guest-information__dates__block__date" :style="{ color: textColor }">
          {{ checkOutDate }}
        </div>
      </div>
    </div>
    <div
      v-if="showCheckinButton"
      class="c-body-widget-guest-information__button"
      :class="[showSquareBorders ? 'show-square-borders' : '']"
      :style="{ 'background-color': buttonColor, color: buttonTextColor }"
      @click="action"
    >
      {{ buttonText }}
    </div>
  </div>
</template>

<script>
import { translate, getImageUrl, getLocale } from "@/services/utils.js";
import moment from "moment";
import { StayUiIcon } from "@stay/ui";
const userStatuses = {
  VISITOR: "VISITOR",
  PRE_CLIENT: "PRE_CLIENT",
  CLIENT: "CLIENT"
};

export default {
  components: {
    StayUiIcon
  },
  props: {
    widget: {
      type: Object,
      required: true
    },
    showSquareBorders: {
      type: Boolean,
      required: false
    }
  },
  emits: ["action"],
  data() {
    return {};
  },
  computed: {
    widgetStyle() {
      return {
        backgroundColor: this.backgroundColor,
        opacity: this.widgetIsDisplayed ? 1 : 0,
        height: this.widgetIsDisplayed ? "auto" : "0",
        margin: this.widgetIsDisplayed ? "16px" : "0",
        padding: this.widgetIsDisplayed ? "16px" : "0",
        borderRadius: this.showSquareBorders ? "0" : "8px"
      };
    },
    widgetIsDisplayed() {
      return (this.user?.checkIn && this.user?.checkOut) || this.forcedVisible;
    },
    user() {
      return this.$store.state.user;
    },
    text() {
      if (this.forcedVisible) {
        return "Guest Name";
      }
      if (!this.user?.name) {
        return "";
      }
      return this.user.name;
    },
    checkInDate() {
      if (this.forcedVisible) {
        return this.getFormattedDate(new Date());
      }
      if (!this.user?.checkIn) {
        return "";
      }
      return this.getFormattedDate(this.user.checkIn);
    },
    checkOutDate() {
      if (this.forcedVisible) {
        const date = new Date();
        date.setDate(date.getDate() + 1);
        return this.getFormattedDate(date);
      }
      if (!this.user?.checkOut) {
        return "";
      }
      return this.getFormattedDate(this.user.checkOut);
    },
    showImage() {
      return this.widget.showImage && this.image;
    },
    image() {
      return this.widget.photographs ? this.widget.photographs[0] : null;
    },
    showCheckinButton() {
      return this.widget?.showCheckinButton;
    },
    backgroundColor() {
      if (!this.widget?.backgroundColor) {
        return "white";
      }
      return this.widget.backgroundColor;
    },
    textColor() {
      if (!this.widget?.textColor) {
        return "#2c3e50";
      }
      return this.widget.textColor;
    },
    buttonColor() {
      if (!this.widget?.buttonColor) {
        return this.$store.state.mainColor;
      }
      return this.widget.buttonColor;
    },
    buttonTextColor() {
      if (!this.widget?.buttonTextColor) {
        return "white";
      }
      return this.widget.buttonTextColor;
    },
    buttonText() {
      if (!this.widget?.buttonText) {
        return "";
      }
      return translate(this.widget.buttonText);
    },
    isModeCMS() {
      return this.$router.currentRoute.value.query.mode === "cms";
    },
    forcedVisible() {
      if (this.$router.currentRoute.value.query.stayStatus && this.isModeCMS) {
        const status = this.$router.currentRoute.value.query.stayStatus;
        return status === "preStay" || status === "stay";
      }
      return false;
    }
  },
  methods: {
    getImageUrl,
    action() {
      this.$emit("action", this.widget);
    },
    getFormattedDate(date) {
      const dates = {
        day: new moment(date).locale(getLocale()).format("ddd, MMM DD")
      };
      return dates.day;
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.c-body-widget-guest-information {
  width: calc(100% - 64px);
  margin: 16px;
  border-radius: 8px;
  border: 1px solid #e6ebf2;
  padding: 16px;
  &__image {
    width: 100%;
    height: 80px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 16px;
    &.show-square-borders {
      border-radius: 0 !important;
    }
  }

  &__title {
    .h4-black;
    font-size: 18px;
    text-align: center;
  }
  &__dates {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &__block {
      &__title {
        .caption-black;
        .medium;
        font-size: 11px;
      }
      &__date {
        .body-black;
        .medium;
      }
    }
  }

  &__button {
    .subheader-white;
    padding: 8px 16px;
    margin-top: 16px;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    &.show-square-borders {
      border-radius: 0 !important;
    }
  }
}
.body-widget-text-separator-wrapper {
  width: 100%;
  min-height: 125px;
}
.body-widget-text-separator {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.body-widget-text-separator__text {
  font-weight: 400;
  text-align: center;
  padding: 0 16px;
}
.body-widget-text-separator__image {
  width: auto;
  height: auto;
  margin-top: 8px;
}
</style>
