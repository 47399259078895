<template>
  <div ref="element" class="body-widget-next-activities-container">
    <body-widget-title-wrapper
      v-if="widget?.widgetTitleOptions?.active"
      :widget-title-options="widget.widgetTitleOptions"
      :frontpage-text-color="frontpageTextColor"
      @action-all="actionAll"
    />
    <div class="body-widget-next-activities-cards-wrapper">
      <div v-if="loading" class="loading-container">
        <div class="loader" />
      </div>
      <div
        v-for="(card, index) in cards.slice(0, maxItems)"
        :key="card.id"
        class="body-widget-next-activities-card-item"
        :class="animateClass"
      >
        <body-widget-activity-card
          :widget="widget"
          :card="card"
          :card-options="widget.cardOptions"
          :extra-info="extraInfo"
          :frontpage-text-color="frontpageTextColor"
          :show-square-borders="showSquareBorders"
          :title-class="'subheader-black medium text-left'"
          @action="action(card, index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { moduleForServiceType } from "@/services/utils.js";
import widgetsFrontpageMixin from "@/mixins/widgetsFrontpageMixin.js";
import moment from "moment";
const MAX_ITEMS = 3;
export default {
  components: {
    BodyWidgetTitleWrapper: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Wrappers/BodyWidgetTitleWrapper.vue")
    ),
    BodyWidgetActivityCard: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Cards/BodyWidgetActivityCard.vue")
    )
  },
  mixins: [widgetsFrontpageMixin],
  props: {
    widget: {
      type: Object,
      required: true
    },
    frontpageTextColor: {
      type: String,
      required: false
    },
    showSquareBorders: {
      type: Boolean,
      required: false,
      default: false
    },
    scroll: {
      type: Number,
      required: false
    }
  },
  emits: ["action", "actionAll"],
  data() {
    return {
      maxItems: MAX_ITEMS,
      cards: [],
      extraInfo: {},
      loading: false,
      loaded: false,
      visibleOnScreen: true,
      animateClass: "animated fadeIn"
    };
  },
  watch: {
    scroll() {
      this.visibleOnScreen = this.$refs.element.getBoundingClientRect().top < window.innerHeight;
      if (this.visibleOnScreen && !this.loaded && !this.loading) {
        this.loadItems();
      }
    }
  },
  mounted() {
    this.visibleOnScreen = this.$refs.element.getBoundingClientRect().top < window.innerHeight;
    if (this.visibleOnScreen) {
      this.loadItems();
    }
  },
  methods: {
    async loadItems() {
      this.loading = true;
      this.loaded = false;
      this.animateClass = "animated fadeIn";
      try {
        const preStored = this.getStoredItemsForWidget(this.widget);
        if (preStored?.length > 0) {
          this.animateClass = "";
          this.cards = preStored;
          this.loading = false;
          this.loaded = true;
        }
        const response = await this.getItemsForWidget(this.widget);
        if (response) {
          this.cards = response;
        }
        this.loading = false;
        this.loaded = true;
      } catch (error) {
        console.error("error", error);
        this.loading = false;
      }
    },
    actionAll(payload) {
      const widget = {
        itemType: 1,
        itemId: moduleForServiceType["eventcalendar"],
        cmsModule: moduleForServiceType["eventcalendar"]
      };
      if (this.widget.grouping) {
        widget.itemType = 3;
        widget.itemId = this.widget.grouping;
        widget.cmsModule = moduleForServiceType["grouping"];
      }
      this.$emit("actionAll", {
        ...payload,
        ...{
          widget: widget
        }
      });
    },
    action(payload, index) {
      this.$emit("action", {
        ...payload,
        ...{
          widget: this.widget,
          index: index,
          itemType: 3,
          itemId: payload.service,
          cmsModule: moduleForServiceType["eventcalendar"]
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.loading-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
}
.loader {
  border: 3px solid #697181;
  border-radius: 50%;
  border-left-color: #ebedf0;
  width: 54px;
  height: 54px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.body-widget-next-activities-date {
  width: calc(100% - 32px);
  text-align: center;
  .h4-black;
  margin: 16px 16px 0 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid @color-gray-2;
}
.body-widget-next-activities-date::first-letter {
  text-transform: uppercase;
}
.body-widget-next-activities-cards-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: no-wrap;
  padding: 0 16px 0 16px;
  flex-basis: 100%;
  overflow: hidden;
  min-height: 142px;
  width: calc(100% - 32px);
}
.body-widget-next-activities-cards-wrapper::-webkit-scrollbar {
  display: none;
}
.body-widget-next-activities-card-item {
  width: 100%;
}
</style>
