<template>
  <div class="body-widget-wrapper">
    <div id="top" />
    <slot v-for="(widget, index) in widgets">
      <div
        v-if="showWidget(widget)"
        :id="widgets.indexOf(widget)"
        :key="widget.id"
        class="body-widget"
        :class="[getWidgetComponent(widget), forcedVisibleClass]"
      >
        <component
          :is="getWidgetComponent(widget)"
          :widget="widget"
          :frontpage-text-color="frontpageTextColor"
          :show-square-borders="showSquareBorders"
          :scroll="scroll"
          :widget-index="index"
          @action="action"
          @action-all="actionAll"
          @cta-action="ctaAction"
        ></component>
      </div>
    </slot>
    <div v-if="extraDivBottomHeight" :style="{ height: extraDivBottomHeight + 'px' }"></div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { bodyWidgets, headerWidgets, widgetPlaces } from "@/utils/enums/widgetsFrontpage.js";
import { getUserStatusOnlyFromDates } from "@/services/utils.js";
import { EventBus } from "@/services/eventBus.js";
const userStatuses = {
  VISITOR: "VISITOR",
  PRE_CLIENT: "PRE_CLIENT",
  CLIENT: "CLIENT"
};
export default {
  components: {
    BodyWidgetSlider: defineAsyncComponent(() => import("@/components/WidgetsFrontpage/Widgets/BodyWidgetSlider.vue")),
    BodyWidgetCategoryWithChildren: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Widgets/BodyWidgetCategoryWithChildren.vue")
    ),
    BodyWidget3Bubbles: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Widgets/BodyWidget3Bubbles.vue")
    ),
    BodyWidgetNextActivities: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Widgets/BodyWidgetNextActivities.vue")
    ),
    BodyWidgetTextSeparator: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Widgets/BodyWidgetTextSeparator.vue")
    ),
    BodyWidgetBanner: defineAsyncComponent(() => import("@/components/WidgetsFrontpage/Widgets/BodyWidgetBanner.vue")),
    BodyWidgetHighlights: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Widgets/BodyWidgetHighlights.vue")
    ),
    BodyWidgetButton: defineAsyncComponent(() => import("@/components/WidgetsFrontpage/Widgets/BodyWidgetButton.vue")),
    BodyWidgetShortcuts: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Widgets/BodyWidgetShortcuts.vue")
    ),
    BodyWidgetUpsell: defineAsyncComponent(() => import("@/components/WidgetsFrontpage/Widgets/BodyWidgetUpsell.vue")),
    BodyWidgetLineSeparator: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Widgets/BodyWidgetLineSeparator.vue")
    ),
    BodyWidgetVideo: defineAsyncComponent(() => import("@/components/WidgetsFrontpage/Widgets/BodyWidgetVideo.vue")),
    BodyWidgetLinkedCongress: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Widgets/BodyWidgetLinkedCongress.vue")
    ),
    BodyWidgetGuestInformation: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Widgets/BodyWidgetGuestInformation.vue")
    )
  },
  props: {
    widgets: {
      type: Array,
      required: true
    },
    frontpageTextColor: {
      type: String,
      required: false
    },
    extraDivBottomHeight: {
      type: Number,
      required: false
    },
    showSquareBorders: {
      type: Boolean,
      required: false,
      default: false
    },
    scroll: {
      type: Number,
      required: false
    }
  },
  emits: ["action", "actionAll", "ctaAction"],
  computed: {
    cmsMode() {
      return this.$store.state.cmsMode;
    },
    isDraft() {
      return this.$router.currentRoute.value.query.draft === "true";
    },
    forcedVisibleClass() {
      return this.cmsMode || this.isDraft ? "visible" : "";
    },
    user() {
      return this.$store.state.user;
    },
    isModeCMS() {
      return this.$router.currentRoute.value.query.mode === "cms";
    },
    forcedStatusMode() {
      if (this.$router.currentRoute.value.query.stayStatus && this.isModeCMS) {
        switch (this.$router.currentRoute.value.query.stayStatus) {
          case "visitor":
            return userStatuses.VISITOR;
          case "preStay":
            return userStatuses.PRE_CLIENT;
          case "stay":
            return userStatuses.CLIENT;
        }
        return this.$router.currentRoute.value.query.stayStatus;
      }
      return false;
    },
    forcedSegmentationFilter() {
      if (this.$router.currentRoute.value.query.segmentationFilter && this.isModeCMS) {
        return this.$router.currentRoute.value.query.segmentationFilter;
      }
      return false;
    }
  },
  mounted() {
    this.observeWidgets();
  },
  created() {
    EventBus.$off("displayWelcomePopup");
    EventBus.$on("displayWelcomePopup", () => {
      this.observeWidgets();
    });
    EventBus.$off("loggedOut");
    EventBus.$on("loggedOut", () => {
      this.observeWidgets();
    });
  },
  methods: {
    getWidgetComponent(widget) {
      if (widget.place === widgetPlaces.HEADER) {
        return headerWidgets[widget.templateId];
      }
      return bodyWidgets[widget.templateId];
    },
    action(payload) {
      this.$emit("action", payload);
    },
    actionAll(payload) {
      this.$emit("actionAll", payload);
    },
    ctaAction(payload) {
      this.$emit("ctaAction", payload);
    },
    showWidget(widget) {
      if (this.$router.currentRoute.value.query.demoWidget) {
        return true;
      }
      if (widget.hidden) {
        return false;
      }
      if (widget.showUpsell) {
        return true;
      }
      if (this.forcedStatusMode === "all") {
        return true;
      }
      let showWidgetBecauseOfStatus = true;
      let showWidgetBecauseOfSegmentation = true;
      const userEstablishmentData = this.user?.establishments?.[String(this.user?.establishmentId)];
      if (widget.visibility) {
        showWidgetBecauseOfStatus = false;
        let userStatus = getUserStatusOnlyFromDates(userEstablishmentData);
        if (this.forcedStatusMode) {
          userStatus = this.forcedStatusMode;
        }
        switch (userStatus) {
          case userStatuses.VISITOR:
            showWidgetBecauseOfStatus = widget.visibility.visitor;
            break;
          case userStatuses.PRE_CLIENT:
            showWidgetBecauseOfStatus = widget.visibility.preStay;
            break;
          case userStatuses.CLIENT:
            showWidgetBecauseOfStatus = widget.visibility.stay;
            break;
        }
      }
      if (widget.segmentation?.active === true) {
        showWidgetBecauseOfSegmentation = false;
        let segmentationToApply = null;

        if (this.forcedSegmentationFilter) {
          segmentationToApply = this.forcedSegmentationFilter.split(",");
        } else if (userEstablishmentData?.segmentation) {
          segmentationToApply = Object.values(userEstablishmentData?.segmentation);
        }
        if (!segmentationToApply) {
          showWidgetBecauseOfSegmentation = false;
        }
        showWidgetBecauseOfSegmentation =
          widget.segmentation.values.filter(el => segmentationToApply?.includes(el)).length > 0;
      }
      if (widget.visibility || widget.segmentation?.active) {
        return showWidgetBecauseOfStatus && showWidgetBecauseOfSegmentation;
      }
      return true;
    },
    observeWidgets() {
      const options = {
        rootMargin: "0px",
        threshold: 0.1
      };
      const observer = new IntersectionObserver(this.handleIntersection, options);
      const targets = document.querySelectorAll(".body-widget");
      targets.forEach(target => {
        if (this.cmsMode) {
          target.classList.add("visible");
        } else {
          observer.observe(target);
        }
      });
    },
    handleIntersection(entries) {
      entries.map(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        } else {
          entry.target.classList.remove("visible");
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.body-widget-wrapper {
  .body-widget {
    width: 100%;
    padding-bottom: 16px;
    opacity: 0;
    &.BodyWidgetTextSeparator,
    &.BodyWidgetGuestInformation,
    &.BodyWidgetSlider {
      padding-bottom: 0;
    }
    &.visible {
      opacity: 1;
    }
  }
}
</style>
