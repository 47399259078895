const headerWidgets = {
  1: "HeaderWidgetStandard",
  2: "HeaderWidgetUpsell",
  3: "HeaderWidgetMini"
};
const bodyWidgets = {
  1: "BodyWidgetCategoryWithChildren",
  2: "BodyWidgetSlider",
  3: "BodyWidget3Bubbles",
  4: "BodyWidgetNextActivities",
  5: "BodyWidgetTextSeparator",
  6: "BodyWidgetBanner",
  7: "BodyWidgetHighlights",
  8: "BodyWidgetButton",
  9: "BodyWidgetSlider",
  10: "BodyWidgetShortcuts",
  11: "BodyWidgetLineSeparator",
  12: "BodyWidgetVideo",
  13: "BodyWidgetSlider",
  14: "BodyWidgetLinkedCongress",
  15: "BodyWidgetGuestInformation"
};
const footerOptions = {
  FOOTER_OPTION_SHORTCUTS: 1,
  FOOTER_OPTION_BUBBLE: 2
};
const footerWidgets = {
  [footerOptions.FOOTER_OPTION_SHORTCUTS]: "FooterWidgetFloatingShortcuts",
  [footerOptions.FOOTER_OPTION_BUBBLE]: "FooterWidgetFloatingBubble"
};
const cardOptions = {
  1: "BodyWidgetSliderCardBG",
  2: "BodyWidgetSliderCardBG",
  3: "BodyWidgetSliderCardBG",
  4: "BodyWidgetSliderCardBox4_3",
  5: "BodyWidgetSliderCardBox1_1",
  6: "BodyWidgetSliderCardBox1_1",
  7: "BodyWidgetActivityCard",
  8: "BodyWidgetSliderListCard",
  9: "BodyWidgetSliderListCard",
  10: "BodyWidgetSliderCardBox1_1",
  11: "BodyWidgetSliderCardBox1_1",
  12: "BodyWidgetSliderCardIHA",
  13: "BodyWidgetSliderCardUpcomingActivity"
};
const widgetTitles = {
  1: "BodyWidgetTitle2",
  2: "BodyWidgetTitle2",
  3: "BodyWidgetTitle2"
};
const cardSubtitles = {
  1: "BodyWidgetCardSubtitleLocation",
  2: "BodyWidgetCardSubtitleOpeningHours",
  3: "BodyWidgetCardSubtitlePriceFrom",
  4: "BodyWidgetCardSubtitleBookingTime",
  5: "BodyWidgetCardSubtitleHoursAndAvailability",
  6: "BodyWidgetCardSubtitleOfferPrice"
};
const ctas = {
  1: "BodyWidgetCta1",
  2: "BodyWidgetCta1"
};
const banners = {
  1: "BodyWidgetBanner1",
  2: "BodyWidgetBanner1",
  3: "BodyWidgetBanner2",
  4: "BodyWidgetBanner3"
};
const cardStyles = {
  CARD_STYLE_BG_4_3: 1,
  CARD_STYLE_BG_9_16: 2,
  CARD_STYLE_BG_1_1: 3,
  CARD_STYLE_4_3: 4,
  CARD_STYLE_1_1_SQUARE: 5,
  CARD_STYLE_1_1_ROUND: 6,
  CARD_STYLE_7: 7,
  CARD_STYLE_LIST_1_1_SQUARE: 8,
  CARD_STYLE_LIST_1_1_ROUND: 9,
  CARD_STYLE_TILES_1_1_SQUARE: 10,
  CARD_STYLE_TILES_1_1_ROUND: 11,
  CARD_STYLE_IHA: 12,
  CARD_STYLE_UPCOMING_ACTIVITY: 13
};
const ctaStyles = {
  CTA_STYLE_1: 1,
  CTA_STYLE_2: 2
};
const subtitleColors = {
  WHITE: 1,
  BLACK: 2,
  GRAY: 3
};
const contentTypes = {
  SERVICES: 1,
  PRODUCTS: 3,
  OPEN_SERVICES: 4,
  AVAILABLE_SERVICES: 5,
  UPCOMING_ACTIVITY: 6,
  NEXT_ACTIVITIES: 7,
  PROMOTED_PRODUCTS: 8,
  MOST_REQUESTED_PRODUCTS: 9,
  MANUAL_CONTENT: 10
};
const bannerStyles = {
  BANNER_STYLE_1: 1,
  BANNER_STYLE_2: 2
};
const textAlignments = {
  TEXT_ALIGNMENT_LEFT: 0,
  TEXT_ALIGNMENT_CENTER: 1
};
const orientationStyles = {
  ORIENTATION_STYLE_TILES: 1,
  ORIENTATION_STYLE_LIST: 2,
  ORIENTATION_STYLE_SLIDER: 3
};
const widgetPlaces = {
  HEADER: 0,
  BODY: 1,
  FOOTER: 2
};
const headerBackgroundModes = {
  BACKGROUND_MODE_IMAGE: 0,
  BACKGROUND_MODE_COLOR: 1,
  BACKGROUND_MODE_VIDEO: 2
};
const headlineModes = {
  HEADLINE_MODE_PROPERTY_NAME: 0,
  HEADLINE_MODE_TEXT: 1
};
const miniHeaderTitleTypes = {
  MINI_HEADER_TITLE_TYPE_TEXT: 1,
  MINI_HEADER_TITLE_TYPE_LOGO: 2
};

const headerTypes = {
  HEADER_TYPE_STANDARD: 1,
  HEADER_TYPE_UPSELL: 2,
  HEADER_TYPE_MINI: 3
};

export {
  headerWidgets,
  footerOptions,
  footerWidgets,
  bodyWidgets,
  cardOptions,
  widgetTitles,
  cardSubtitles,
  ctas,
  banners,
  bannerStyles,
  cardStyles,
  ctaStyles,
  contentTypes,
  subtitleColors,
  textAlignments,
  orientationStyles,
  widgetPlaces,
  headerBackgroundModes,
  headlineModes,
  miniHeaderTitleTypes,
  headerTypes
};
