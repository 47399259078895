<template>
  <div ref="element" class="body-widget-slider-container" :style="sliderStyle">
    <body-widget-title-wrapper
      v-if="showWidgetTitle"
      :widget-title-options="widget.widgetTitleOptions"
      :frontpage-text-color="frontpageTextColor"
      :content-type="widget.contentType"
      @action-all="actionAll"
    />
    <div class="body-widget-slider-cards-wrapper" :style="cardsWrapperStyle">
      <div v-if="loading" class="loading-container">
        <div class="loader" />
      </div>
      <div
        v-for="(card, index) in cardsToShow"
        :key="card.id"
        class="body-widget-slider-card-item"
        :class="animateClass"
        :style="cardItemStyle"
      >
        <body-widget-slider-card-wrapper
          :widget="widget"
          :card="card"
          :extra-info="extraInfo"
          :show-square-borders="showSquareBorders"
          @action="action(card, index)"
          @cta-action="ctaAction(card, index)"
        />
      </div>
      <body-widget-placeholder
        v-if="loaded && cards.length === 0"
        :title="getString('NO_CONTENT')"
        icon-name="shows_placeholder.webp"
        :height="100"
      />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import widgetsFrontpageMixin from "@/mixins/widgetsFrontpageMixin.js";
import { getString } from "@/services/utils.js";
import { orientationStyles, contentTypes } from "@/utils/enums/widgetsFrontpage.js";

export default {
  components: {
    BodyWidgetPlaceholder: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Placeholders/BodyWidgetPlaceholder1.vue")
    ),
    BodyWidgetTitleWrapper: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Wrappers/BodyWidgetTitleWrapper.vue")
    ),
    BodyWidgetSliderCardWrapper: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Wrappers/BodyWidgetSliderCardWrapper.vue")
    )
  },
  mixins: [widgetsFrontpageMixin],
  props: {
    widget: {
      type: Object,
      required: true
    },
    frontpageTextColor: {
      type: String,
      required: false
    },
    showSquareBorders: {
      type: Boolean,
      required: false
    },
    scroll: {
      type: Number,
      required: false
    }
  },
  emits: ["action", "actionAll", "ctaAction"],
  data() {
    return {
      cards: [],
      extraInfo: {},
      loading: false,
      loaded: false,
      visibleOnScreen: true,
      animateClass: "animated fadeIn"
    };
  },
  computed: {
    strings() {
      return this.$store.state.strings;
    },
    user() {
      return this.$store.state.user;
    },
    showWidgetTitle() {
      return this.widget?.widgetTitleOptions?.active && this.cardsToShow.length > 0;
    },
    sliderStyle() {
      if (this.widget?.contentType === contentTypes.UPCOMING_ACTIVITY) {
        const style = {
          "max-height": "600px",
          opacity: 1
        };
        style.transition = "max-height 0.7s, opacity 0.5s";
        if (this.cardsToShow.length === 0) {
          style["max-height"] = "0px";
          style.opacity = 0;
          style.display = "none";
        }
        return style;
      }
      return { "padding-bottom": "16px" };
    },
    cardsWrapperStyle() {
      switch (this.widget?.cardOptions?.orientation) {
        case orientationStyles.ORIENTATION_STYLE_TILES:
          return {
            "flex-wrap": "wrap"
          };
        case orientationStyles.ORIENTATION_STYLE_LIST:
          return {
            "flex-direction": "column",
            "padding-right": "16px"
          };
      }
      return { "flex-wrap": "nowrap" };
    },
    cardItemStyle() {
      switch (this.widget?.cardOptions?.orientation) {
        case orientationStyles.ORIENTATION_STYLE_TILES:
          return {
            "margin-right": "8px",
            "margin-bottom": "16px"
          };
        case orientationStyles.ORIENTATION_STYLE_LIST:
          return {
            width: "100%",
            "margin-bottom": "8px"
          };
      }
      return { "margin-right": "16px" };
    },
    cardsToShow() {
      switch (this.widget?.cardOptions?.orientation) {
        case orientationStyles.ORIENTATION_STYLE_TILES:
        case orientationStyles.ORIENTATION_STYLE_LIST:
        case orientationStyles.ORIENTATION_STYLE_SLIDER: {
          if (this.widget.cardOptions.limitItems && this.widget.cardOptions.limitItemsNumber > 0) {
            return this.cards.slice(0, parseInt(this.widget.cardOptions.limitItemsNumber));
          }
        }
      }
      return this.cards;
    }
  },
  watch: {
    scroll() {
      this.visibleOnScreen = this.$refs.element.getBoundingClientRect().top < window.innerHeight;
      if (this.visibleOnScreen && !this.loaded && !this.loading) {
        this.loadItems();
      }
    }
  },
  mounted() {
    this.visibleOnScreen = this.$refs.element.getBoundingClientRect().top < window.innerHeight;
    if (this.visibleOnScreen) {
      this.loadItems();
    }
  },
  methods: {
    getString(key) {
      return getString(this.strings, key, this.user);
    },
    async loadItems() {
      this.loading = true;
      this.loaded = false;
      this.animateClass = "animated fadeIn";
      try {
        const preStored = this.getStoredItemsForWidget(this.widget);
        if (preStored?.length > 0) {
          this.animateClass = "";
          this.cards = preStored;
          this.loading = false;
          this.loaded = true;
        }
        const response = await this.getItemsForWidget(this.widget);
        if (response) {
          this.cards = response;
        }
        this.loading = false;
        this.loaded = true;
      } catch (error) {
        console.error("error", error);
        this.loading = false;
      }
    },
    action(payload, index) {
      this.$emit("action", { ...payload, ...{ widget: this.widget, index: index } });
    },
    actionAll(payload) {
      this.$emit("actionAll", { ...payload, ...{ widget: this.widget } });
    },
    ctaAction(payload, index) {
      this.$emit("ctaAction", { ...payload, ...{ widget: this.widget, index: index } });
    }
  }
};
</script>

<style lang="less" scoped>
.loading-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
}
.loader {
  border: 3px solid #697181;
  border-radius: 50%;
  border-left-color: #ebedf0;
  width: 54px;
  height: 54px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.body-widget-slider-cards-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  padding: 16px 0 16px 16px;
  flex-basis: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 130px;
  transition: flex 1s;
}
.body-widget-slider-cards-wrapper::-webkit-scrollbar {
  display: none;
}
.body-widget-slider-card-item {
  margin-right: 16px;
}
</style>
