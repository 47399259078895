<template>
  <div class="header-widget-wrapper">
    <component
      :is="getWidgetComponent(widget)"
      :widget="widget"
      :frontpage-background-color="frontpageBackgroundColor"
      :frontpage-text-color="frontpageTextColor"
      :fixed-header-visible="fixedHeaderVisible"
      :frontpage-data="frontpageData"
      :show-square-borders="showSquareBorders"
      @height="emitHeight"
      @show-inbox="showInbox"
      @show-user-profile="showUserProfile"
      @shortcut-tapped="shortcutTapped"
      @show-front-side-menu="showFrontSideMenu"
    ></component>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { headerTypes, headerWidgets } from "@/utils/enums/widgetsFrontpage.js";
export default {
  components: {
    HeaderWidgetStandard: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Widgets/HeaderWidgetStandard.vue")
    ),
    HeaderWidgetUpsell: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Widgets/HeaderWidgetUpsell.vue")
    ),
    HeaderWidgetMini: defineAsyncComponent(() => import("@/components/WidgetsFrontpage/Widgets/HeaderWidgetMini.vue"))
  },
  props: {
    widget: {
      type: Object,
      required: true
    },
    frontpageBackgroundColor: {
      type: String,
      required: false
    },
    frontpageTextColor: {
      type: String,
      required: false
    },
    fixedHeaderVisible: {
      type: Boolean,
      required: false
    },
    frontpageData: {
      type: Object || null,
      required: false
    },
    showSquareBorders: {
      type: Boolean,
      default: false
    }
  },
  emits: ["height", "showInbox", "showUserProfile", "shortcutTapped", "showFrontSideMenu"],
  methods: {
    getWidgetComponent(widget) {
      let widgetId = widget.templateId;
      if (widget.headerType) {
        widgetId = widget.headerType;
      } else if (widget.templateId === headerTypes.HEADER_TYPE_STANDARD && widget.upsell?.showUpsell) {
        widgetId = headerTypes.HEADER_TYPE_UPSELL;
      }
      return headerWidgets[widgetId];
    },
    emitHeight(height) {
      this.$emit("height", height);
    },
    showInbox() {
      this.$emit("showInbox");
    },
    showUserProfile() {
      this.$emit("showUserProfile");
    },
    shortcutTapped(payload) {
      this.$emit("shortcutTapped", payload);
    },
    showFrontSideMenu() {
      this.$emit("showFrontSideMenu");
    }
  }
};
</script>

<style lang="less" scoped></style>
