import { defineComponent as u, useCssVars as l, useAttrs as m, computed as t, openBlock as f, createBlock as d, resolveDynamicComponent as v, unref as c, mergeProps as z } from "vue";
import _ from "./HugeiconsItems.js";
import { _ as y } from "../../_plugin-vue_export-helper-CHgC5LLL.js";
import '../../assets/StayUiIcon.css';const C = /* @__PURE__ */ u({
  __name: "StayUiIcon",
  props: {
    icon: {},
    size: { default: 20 },
    variant: { default: "stroke" },
    type: { default: "rounded" }
  },
  setup(n) {
    l((e) => ({
      a15c6242: i.value,
      "01408abe": p.value
    }));
    const a = m(), s = (e) => e.replace(/^.|-./g, (o) => (o[1] ?? o[0]).toUpperCase()), r = t(() => `${s(n.icon)}Icon`), i = t(() => `${n.size}px`), p = t(() => a.color || "currentColor");
    return (e, o) => (f(), d(v(c(_)[r.value]), z({ ...c(a), type: e.type, variant: e.variant }, {
      class: "ui-icon",
      size: e.size
    }), null, 16, ["size"]));
  }
}), S = /* @__PURE__ */ y(C, [["__scopeId", "data-v-f0c12f14"]]);
export {
  S as default
};
