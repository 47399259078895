<template>
  <component
    :is="iconSelected"
    v-bind="$attrs"
    class="c-icon"
    :class="[{ 'c-icon--circle': props.circle, [`c-icon--circle--${props.circle}`]: props.circle }]"
    size="50"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    icon: string;
    size?: number;
    strokeWidth?: number | string;
    variant?: "stroke" | "solid" | "duotone" | "twotone" | "bulk";
    type?: "rounded" | "sharp" | "standard";
    legacy?: boolean;
    circle?: "dark" | "light";
  }>(),
  {
    size: 24,
    type: "standard",
    variant: "stroke"
  }
);

const iconSelected = computed(() => {
  const modules = import.meta.glob("../../../assets/icons/**/*.svg", {
    eager: true
  });
  const path = `../../../assets/icons/${props.icon}.svg`;
  return modules[path];
});
const iconSize = computed(() => `${props.size}px`);
</script>

<style scoped lang="less">
.c-icon {
  width: v-bind(iconSize);
  aspect-ratio: 1;
  cursor: pointer;
  font-size: v-bind(iconSize);
  color: v-bind("$attrs.color");

  * {
    box-sizing: border-box;
  }

  &--circle {
    display: flex !important;
    justify-content: center;
    align-items: center;

    width: fit-content;
    padding: 8px;
    border-radius: 50%;

    &--dark {
      border: 1px solid rgba(255, 255, 255, 0.5);

      background: rgba(19, 19, 25, 0.6);
      --r: calc(19 * 0.2126);
      --g: calc(19 * 0.7152);
      --b: calc(25 * 0.0722);
      --sum: calc(var(--r) + var(--g) + var(--b));
      --perceived-lightness: calc(var(--sum) / 255);
      --threshold: 0.5;

      /* shows either white or black color depending on perceived darkness */
      color: hsl(0, 0%, calc((var(--perceived-lightness) - var(--threshold)) * -10000000%));
    }

    &--light {
      border: 1px solid rgba(235, 237, 240);
      background-color: rgba(255, 255, 255);
    }
  }
}
</style>
